@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.profile-info-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  padding: 35px 0 0 30px;
  border-radius: 15px;
  width: 650px;
  min-height: 567px;
  max-height: 567px;
  background-color: $white;

  &--schedule {
    min-width: 344px;
    max-width: 344px;
    margin-left: 20px;
    padding: 35px 0 0 30px;
    justify-content: space-between;
  }

  &--gallery {
    width: 650px;
    margin-top: 20px;
    min-height: 305px;
    max-height: 305px;
  }

  &--brands {
    width: 344px;
    margin: 20px 0 0 20px;
    min-height: 305px;
    max-height: 305px;
    justify-content: space-between;
  }
}

.station-name {
  font: 34px Montserrat;
  letter-spacing: -0.09px;
  color: $text-color-input;

  &--reminders {
    margin: 8px 0 28px 0;
  }

  &--gallery {
    margin-left: 25px;
  }
}

.field-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.title-field, .field-value {
  font: 14px MontserratRegular;
  letter-spacing: 1.17px;
  color: $text-color-input;
  margin-bottom: 5px;


  &.field-value {
    font: 20px MontserratRegular;
    letter-spacing: 1.67px;
    margin-bottom: 0;
    max-width: 240px;
    height: 24px;
  }
}

.contact-info, .contact-info-wrap, .button-wrap, .button-wrap-sign-in {
  display: flex;
  flex-direction: row;

  &.button-wrap-sign-in {
    margin-top: 15px;
  }

  &.contact-info-wrap {
    flex-direction: column;
    margin-right: 72px;

    &--info {
      margin-right: 0;
    }
  }

  &.button-wrap {
    justify-content: flex-end;
    margin-right: 30px;
  }
}

.button-price-list {
  min-width: 108px;
  width: auto;
  background-color: $icon-active-background;
  display: flex;
  align-items: center;
}

.text-btn, .text-btn-price-regular, {
  font: 14px 'Montserrat';
  color: $white;
  letter-spacing: -0.04px;
  margin-left: 10px;

  &.text-btn-price-regular {
    font-family: MontserratRegular;
  }
}

.button-change-password, .button-edit-profile, .button-sign-out, .button-restore-password, .button-remove {
  width: 180px;
  background-color: $icon-active-background;
  color: $white;
  margin-left: 20px;
  letter-spacing: -0.04px;

  &.button-restore-password {
    width: auto;
    margin-left: 0;
  }

  &.button-edit-profile {
    width: 130px;
  }

  &.button-sign-out, &.button-remove {
    width: 112px;
    background-color: $color-text-button;
  }
}

.modal-sign-out {
  width: 590px;
  height: 192px;
}

.sign-out-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.text-sign-out, .text-edit-profile, .title-modal-window {
  font: 20px Montserrat;
  letter-spacing: -0.05px;
  color: $text-color-input;
  margin: 35px 0 20px 0;

  &.title-modal-window {
    margin: 0;
  }
}

.button-wrap-sing-out, .button-wrap-modal {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;

  &.button-wrap-modal {
    margin-top: 20px;
  }
}

.button-cancel, .button-save {
  width: 100px;
  background-color: $icon-active-background;
  color: $white;

  &.button-save {
    background-color: $color-text-button;
    margin-left: 20px;
  }
}

.edit-profile-modal {
  width: 600px;
  height: 673px;
  align-items: center;
}

.schedule-list {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 30px;
}

.day-item-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  width: 100%;
  justify-content: space-between;
}

.time-interval {
  color: $text-color-input;
  letter-spacing: 1.67px;
  font: 20px MontserratRegular;
  margin-right: 30px;

  &_day-item {
    font-family: 'MontserratMedium';
    margin-right: 25px;
  }
}

.schedule-wrap {
  display: flex;
  flex-direction: column;
}

.localization-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px 30px 0;
}

.language-select {
  background-color: $icon-active-background;
  width: 50px;
  font: 14px Montserrat;
  height: 40px;
}


.language-select-wrap {
  width: 50px;

  .css-1uccc91-singleValue {
    color: $white;
    background-color: $icon-active-background;
    max-width: 50px;
    overflow: hidden;
    position: absolute;
    transform: translateY(-50%);
    box-sizing: border-box;
  }

  .css-tlfecz-indicatorContainer {
    display: none;
  }

  .css-1gtu0rj-indicatorContainer {
    display: none;
  }

  .css-yk16xz-control, .css-1pahdxg-control {
    align-items: center;
    justify-content: center;
    display: flex;
    min-height: 40px;
    max-height: 40px;
    outline: 0;
    transition: all 100ms;
    border: none;
    background-color: $icon-active-background;
  }

  .css-6q0nyr-Svg {
    display: none;
  }

  .css-g1d714-ValueContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .language-select__menu {
    background-color: $icon-active-background;
    color: $white;
    text-align: center;
  }

  .language__control {
    height: 40px;
    background-color: $icon-active-background;
    border-radius: 5px;
    border: none;
    min-height: 40px;
    max-height: 40px;
    outline: none;

    &.language-select__control:hover {
      border: none;
      box-shadow: none;
      outline: none;

    }

    &.language___container:focus {
      border: none;
      outline: none;
    }
  }

  &:active,
  &:focus {
    outline: none;
    border: none;
  }

  .css-9gakcf-option {
    background-color: red;
  }

  .css-1n7v3ny-option {
    background-color: $icon-active-background;
  }
}

.schedule {
  display: flex;
  flex-direction: column;

  &--button {
    align-items: flex-end;
    margin: 0 30px 35px 0;
  }
}

.wrapper-page {
  display: flex;
  width: 650px;
  flex-direction: column;
}

.brands-type {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.photos-list {
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  margin: 30px 30px 30px 0;
}

.container-photo {
  min-width: 92px;
  min-height: 92px;
  max-width: 92px;
  max-height: 92px;
  border-radius: 4px;
  background-size: cover;
  margin-right: 20px;
  background-position: center;
}

.empty-state-gallery {
  font: $font-main-regular;
  letter-spacing: -0.22px;
  text-align: center;
  color: $text-empty-state;
  margin: 62px 0 50px 0;
  white-space: pre-line
}

.full-address {
  display: none;
  width: 348px;
  height: 80px;
  border-radius: 8px;
  box-shadow: $shadow-modal-sign-in;
  border: solid 1px $border-color-text;
  color: $text-color-input;
  padding: 16px;
  font: 20px MontserratRegular;
  background-color: $white;
}

.field-value-address {
  font: 20px MontserratRegular;
  letter-spacing: 1.67px;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
  white-space: nowrap;
  max-width: 240px;
  height: 24px;
  color: $text-color-input;
}

.wrap-address:hover .full-address {
  display: block;
  position: absolute;
}

.wrap-address {
  display: flex;
  flex-direction: column;
}


.profile-wrap {
  width: 1015px;
  flex-direction: row;
  display: flex;
  align-items: center;
}


@media all and (max-width: $tablet-max-width) {
  .profile-wrap {
    flex-direction: column;
  }
  .wrapper-page {
    &:last-child {
      flex-direction: row;
    }
  }

  .profile-info-wrap {
    margin-top: 86px;

    &--schedule {
      margin: 16px 0 0 0;
    }

    &--gallery {
      margin-top: 16px;
    }

    &--brands {
      margin: 16px 0 0 16px;
    }
  }
}





