@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.item-photo {
  width: 191px;
  height: 191px;
  border-radius: 15px;
  background-size: cover;
  margin: 0 17px 17px 0;
  background-position: center;
  position: relative;
}

.item-photo:hover .icon-basket {
  display: block;
}


.full-photo {
  width: 100%;
  height: 100%;
  border-radius: 15px;
  background-position: center;
  background-size: cover;
  position: relative;
}

.modal-gallery {
  width: 1015px;
  height: 616px;
}

@media all and (max-width: $tablet-max-width) {
  .item-photo {
    width: 205px;
    height: 205px;
  }
  .modal-gallery {
    max-width: 650px;
    max-height: 616px;
  }
  .item-photo .icon-basket {
    display: block;
  }
}