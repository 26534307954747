@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.item-booking {
  width: 100%;
  max-height: 200px;
  border-radius: 15px;
  background-color: $white;
  margin-bottom: 10px;
}

.item-booking-container {
  display: flex;
  flex-direction: column;
}

.status-booking {
  width: 90px;
  height: 20px;
  letter-spacing: -0.03px;
  border-radius: 12.5px;
  font: 10px Montserrat;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0 0 30px;

  &_pending {
    color: $bg-search;
    background-color: $pending-button-bg;
  }

  &_done {
    color: $button-done;
    background-color: $done-button-bg;
  }
}

.booking-info {
  display: flex;
  flex-direction: row;
  padding: 15px 30px 0 30px;
  align-items: flex-start;
  justify-content: space-between;

  &__wrap {
    flex-direction: column;
  }
}

.booking-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 500px;
}

.item-field {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 15px;
}

.text-booking {
  font: $font-main-regular;
  letter-spacing: 1.17px;
  width: 192px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: auto;
  color: $side-bar-color;
  margin-left: 10px;

  &--unassigned {
    color: $unassigned-text;
  }
}

.description-booking {
  width: 385px;
  margin-bottom: 18px;
  font: $font-main-regular;
  color: $side-bar-color;
  letter-spacing: 1.17px;
}
