@import 'assets/styles/general.scss';
@import 'assets/styles/variables.scss';

.ant-picker-range {
  height: 32px;
  width: 210px;
  background-color: #f2f2f3;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.ant-picker-focused {
  outline: 0;
  -webkit-box-shadow: none;
}
.ant-picker-range .ant-picker-active-bar {
  bottom: -1px;
  height: 2px;
  margin-left: 11px;
  background: #f10038;
  opacity: 0;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  pointer-events: none;
}

.ant-btn-primary {
  color: #fff;
  background: #f10038;
  box-shadow: $shadow-btn-side-bar;
  border: none;
  border-radius: 5px;
  font: 12px MontserratRegular;
}

.ant-btn-primary[disabled], .ant-btn-primary[disabled]:hover, .ant-btn-primary[disabled]:focus, .ant-btn-primary[disabled]:active {
  color: $white;
  font: 12px MontserratRegular;
  background: #f16082;
  border: none;
  text-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ant-picker-clear {
  position: absolute;
  top: 50%;
  right: 0;
  color: rgba(0, 0, 0, 0.25);
  background: #f2f2f3;
  transform: translateY(-50%);
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.3s, color 0.3s;
}
