@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.button-add-employee {
  width: 192px;
  box-shadow: $button-shadow-modal;
  background-color: $color-text-button;
  margin-top: 28px;
}

.button-employee-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.modal-add-employee {
  width: 600px;
  height: 293px;
  border-radius: 15px;
  background-color: $white;
  padding: 35px 40px 30px 40px;
}

.employee-list {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  margin: 10px 0 10px 0;
  min-height: 300px;
  position: relative;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

