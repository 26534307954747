@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.schedule-wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 28px;
  height: 40px;
  width: 910px;
  box-shadow: $shadow-btn-side-bar;
}

.edit-schedule-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.item-day {
  width: 130px;
  height: 40px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-right: 1px solid $main-color;
  color: $select-color;
  cursor: pointer;
  font: 14px MontserratRegular;
  background-color: $white;

  &.active {
    background-color: $color-text-button;
    color: $white;
  }

  &:last-child {
    border-radius: 0 5px 5px 0;
  }

  &:first-child {
    border-radius: 5px 0 0 5px;
  }
}

.info-day-container {
  width: 910px;
  height: 122px;
  border-radius: 15px;
  padding: 35px;
  margin-top: 20px;
  background-color: $white;
}

@media all and (max-width: $tablet-max-width) {
  .schedule-wrapper {
    width: 648px;
    flex-wrap: wrap;
    height: 80px;
  }
  .item-day {
    width: 162px;

    &:nth-last-child(-n+3) {
      width: 216px;
    }
  }
}
