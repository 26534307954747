@import 'assets/styles/general.scss';

.container-role {
  display: flex;
  flex-direction: column;
}

.modal-sign-in {
  width: 1015px;
  height: 620px;
  box-shadow: $shadow-modal-sign-in;
}

