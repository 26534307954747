@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';


.button-add-vehicle {
  width: 176px;
  box-shadow: $button-shadow-modal;
  background-color: $color-text-button;
  margin-left: 20px;
}
