@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.price-list-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.price-list-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 1015px;
  position: relative;
  overflow-y: scroll;
  flex: 1;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.button-add-item {
  background-color: $color-text-button;
  color: $white;
  font-family: MontserratRegular;
  width: 144px;

  &--photo {
    margin-top: 30px;
  }
}

.sorting-panel {
  width: 100%;
  min-height: 50px;
  max-height: 50px;
  border-radius: 15px;
  background-color: $bg-search;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 22px;
}

.input-search-item {
  width: 180px;
  border: solid 1px $border-color;
  background-color: $bg-search;
  padding: 8px 7px 8px 32px;
  font: 12px MontserratRegular;
  color: $white;

  &::placeholder {
    color: $main-color;
  }
}

.sorting-panel-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 20px;
}

.price-list {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 10px;
}

.text-sorting-panel {
  font: 14px Montserrat;
  letter-spacing: -0.04px;
  color: $white;
  margin-left: 20px;
}

.modal-add-item {
  width: 600px;
  height: 380px;
}

.item-product-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.item-wrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 30px;
}

.field-container {
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.container-image {
  min-width: 196px;
  min-height: 196px;
  max-width: 196px;
  max-height: 196px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  position: relative;
}

.add-img-wrap {
  position: absolute;
  background-color: $icon-active-background;
  height: 40px;
  width: 196px;
  bottom: 0;
  box-shadow: $button-shadow-modal !important;
  border-radius: 0 0 15px 15px !important;
}

.input-item-name, .input-item-price {
  width: 304px;
  margin-bottom: 20px;
}

.container-select {
  display: flex;
  flex-direction: column;
  width: 100%;

  &__currency {
    width: 160px;
  }

  &__time {
    width: 230px;
    margin-right: 32px;
  }
}

.css-1okebmr-indicatorSeparator {
  display: none;
}

.css-1gtu0rj-indicatorContainer {
  transform: rotate(180deg);
}


.custom-select-wrap {
  height: 32px;

  .custom-select__control {
    background-color: $main-color;
    height: 32px;
    min-height: 32px;
    max-height: 32px;
    border: none;

    &.custom-select__control:hover {
      border: none;
      box-shadow: none;
    }

    &.custom-select___container:focus {
      border: none;
    }
  }

  &:active,
  &:focus {
    outline: none;
  }
}

.pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 62px;
  width: 100%;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.09);
  background-color: $white;
}

.pagination {
  max-width: 1140px;
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $white;

  li {
    margin-right: 30px;
    cursor: pointer;
    text-align: center;

    &:focus {
      outline: none;
    }

    &.selected {
      a {
        font-weight: 600;
        font-family: Montserrat;
        font-size: 14px;
        width: 40px;
        height: 40px;
        border-radius: 5px;
        box-shadow: $button-shadow-modal;
        background-color: $color-text-button;
        color: $white;
      }

    }

    a {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font: 14px Montserrat;
      color: $black;

      &:focus {
        outline: none;
      }
    }
  }
}

.arrow {
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;

  &_right {
    border-left: 5px solid $side-bar-color;
  }

  &_left {
    border-right: 5px solid $side-bar-color;
  }
}

@media all and (max-width: $tablet-max-width) {
  .price-list-wrap {
    width: 650px;
  }
}



