@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.restore-password-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: $white;
  width: 100vw;
  height: 100vh;
  padding-top: 145px;
}

.input-restore {
  width: 325px;
}

.button-submit {
  width: 100px;
  background-color: $color-text-button;
  margin-top: 15px;
  color: $white;
}

.change-pass-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  margin-bottom: 20px;

  &.error-wrap {
    margin-bottom: 5px;
  }
}
