@import 'variables.scss';

body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  background-color: $main-color;
  overflow: hidden;
  height: auto;
  width: 100vw;
  min-height: 100vh;
}

@font-face {
  font-family: 'Montserrat';
  src: url('/fonts/MontserratSemiBold.ttf');
}

@font-face {
  font-family: 'MontserratRegular';
  src: url('/fonts/MontserratRegular.ttf');
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('/fonts/MontserratMedium.ttf');
}

span.icon {
  background-repeat: no-repeat;
  background-position: center;
  display: flex;

  &-logo {
    width: 49px;
    height: 42px;
    background-image: url($icon-logo);
  }

  &-btn-back {
    width: 40px;
    height: 40px;
    background-image: url($icon-btn-back);
    margin-top: 35px;
    box-shadow: $shadow-btn-side-bar;
    margin-left: 250px;

    &--gallery {
      margin: 0;
    }
  }

  &-btn-next {
    width: 40px;
    height: 40px;
    margin-top: 35px;
    background-image: url($icon-btn-back);
    transform: rotate(180deg);
    margin-left: 50px;
    box-shadow: $shadow-btn-side-bar;
  }

  &-checked {
    width: 138px;
    height: 138px;
    margin-bottom: 40px;
    background-image: url($icon-checked);
  }

  &-price {
    width: 18px;
    height: 18px;
    background-image: url($icon-price);
  }

  &-sign-in {
    width: 67px;
    height: 89px;
    position: absolute;
    background-image: url($icon-sign-in);
  }

  &-plus {
    width: 18px;
    height: 18px;
    background-image: url($icon-plus);
  }

  &-search {
    width: 18px;
    height: 18px;
    position: absolute;
    background-image: url($icon-search);
    margin-left: 10px;
  }

  &-ellipsis {
    width: 21px;
    height: 5px;
    background-image: url($icon-ellipsis);
  }

  &-close-search {
    width: 18px;
    height: 18px;
    background-image: url($icon-close-search);
    position: absolute;
    margin-left: 155px;
  }

  &-filter {
    width: 18px;
    height: 14px;
    background-image: url($icon-filter);
  }

  &-calendar {
    width: 18px;
    height: 18px;
    background-image: url($icon-calendar);
  }

  &-add-booking {
    width: 27px;
    height: 27px;
    background-image: url($icon-add-booking);
  }

  &-booking-calendar {
    width: 18px;
    height: 18px;
    background-image: url($icon-booking-calendar);
  }

  &-clock {
    width: 18px;
    height: 18px;
    background-image: url($icon-clock);
  }

  &-employee {
    width: 18px;
    height: 18px;
    background-image: url($icon-employee);
  }

  &-car-booking {
    width: 18px;
    height: 15px;
    background-image: url($icon-car-booking);
  }

  &-phone {
    width: 18px;
    height: 18px;
    background-image: url($icon-phone);
  }

  &-empty-state {
    width: 380px;
    height: 380px;
    margin-top: 30px;
    background-image: url($icon-empty-state);

    &--employee {
      background-image: url($icon-empty-employee);
    }

    &--reminders {
      background-image: url($icon-empty-reminders);
    }

    &--vehicle {
      background-image: url($icon-empty-vehicle);
    }
  }

  &-eye {
    width: 18px;
    height: 18px;
    position: absolute;
    right: 0;
    margin-right: 10px;
    background-image: url($icon-eye);
  }

  &-eye-visible {
    width: 18px;
    margin-right: 10px;
    height: 18px;
    position: absolute;
    right: 0;
    background-image: url($icon-eye-visible);
  }

  &-close-filter {
    width: 24px;
    height: 24px;
    background-image: url($icon-close-search);
    cursor: pointer;
  }

  &-pin {
    width: 40px;
    height: 40px;
    background-image: url($icon-pin);
    cursor: pointer;
  }

  &-basket {
    width: 32px;
    height: 32px;
    background-image: url($icon-basket);
    position: absolute;
    right: 0;
    display: none;
    margin: 8px 8px 0 0;
    top: 0;
  }

  &-close-modal {
    width: 16px;
    height: 16px;
    background-image: url($icon-x);
    position: absolute;
    right: 0;
    margin: 25px 25px 0 0;
    top: 0;
  }

  &-add-employee {
    width: 26px;
    height: 25px;
    background-image: url($icon-add-employee);
  }

  &-add-reminder {
    width: 25px;
    height: 25px;
    background-image: url($icon-add-reminder);
  }

  &-add-vehicle {
    width: 31px;
    height: 23px;
    background-image: url($icon-add-vehicle);
  }

}

button,
.button {
  cursor: pointer;
  outline: none;
  font-size: 14px;
  font-family: Montserrat;
  border-radius: 5px;
  padding: 10px;
  transition: all 150ms;
  border: none;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: $button-shadow-modal;
  user-select: none;
  // hide all highlight by tap
  -webkit-tap-highlight-color: transparent;
}

@media all and (max-width: $tablet-max-width) {
  span.icon {
    &-btn-next, &-btn-back {
      margin-top: 250px;

      &--gallery {
        margin: 0;
      }
    }
  }
}

.message {
  color: $white;
  font-size: 14px;
  font-family: MontserratRegular !important;
}


.ReactModal__Body--open {
  overflow: hidden;
}

input[type='number'] {
  -moz-appearance: textfield;
}
