@import 'assets/styles/general.scss';
@import 'assets/styles/variables.scss';

.input {
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  background-color: $bg-color-input;
  appearance: none;
  line-height: 0.87;
  border: none;
  letter-spacing: -0.23px;
  color: $text-color-input;
  font: 14px MontserratRegular;
  outline: none;

  &.error {
    border: solid 1px $color-text-button;
  }
}

.container-input {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.label-text {
  opacity: 0.6;
  font: 12px MontserratRegular;
  color: $title-input-color;
  margin-bottom: 5px;
}

.input-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}


