@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.booking-requests-container {
  display: flex;
  min-width: 512px;
  max-width: 512px;
  flex-direction: column;
}

.text-booking-request {
  font: $font-main-regular;
  letter-spacing: 1.17px;
  color: $side-bar-color;
  width: 100%;
  display: flex;
  margin-bottom: 20px;
}

.booking-request-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}