@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.reminders-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}

.reminders-wrap {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 1015px;
  position: relative;
  overflow: hidden;
  flex: 1;

  &::-webkit-scrollbar {
    width: 0;
  }
}

.btn-calendar {
  margin-right: 13px;
  background-color: $icon-active-background;
  display: flex;
  align-items: center;
  color: $white;
  width: 160px;
}

.button-add-reminder {
  width: 188px;
  box-shadow: $button-shadow-modal;
  background-color: $color-text-button;
}

.description-reminders {
  width: 385px;
  font: $font-main-regular;
  color: $side-bar-color;
  letter-spacing: 1.17px;
}

@media all and (max-width: $tablet-max-width) {
  .reminders-wrap {
    width: 650px;
  }
  .text-btn {
    font: 14px MontserratMedium;
  }
  .booking-info__wrap {
    align-items: center;
  }
  .description-reminders {
    margin-bottom: 18px;
    max-width: 385px;
    flex-wrap: wrap;
    display: flex;
  }
}
