//colors
$white: #ffffff;
$black: #000000;
$side-bar-color: #222222;
$side-bar-text: #555555;
$icon-active-background: #333333;
$shadow-btn-side-bar: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$main-color: #F2F2F3;
$button-shadow-modal: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
$bg-color-input: #f2f2f3;
$text-color-input: #222222;
$title-input-color: #020202;
$color-text-button: #f10038;
$menu-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.18);
$bg-search: #504f53;
$border-color: #c7c7c7;
$shadow-modal-sign-in: 0 2px 10px 0 rgba(0, 0, 0, 0.1);
$placeholder-color: #777777;
$radio-button-title: #747475;
$button-done: #169a00;
$pending-button-bg: rgba(80, 79, 83, 0.2);
$done-button-bg: rgba(22, 154, 0, 0.2);
$select-color: #888888;
$unassigned-text: #cccccc;
$shadow-notification: 2px 2px 8px 0 rgba(0, 0, 0, 0.2);
$placeholder-filter: #a5a5a6;
$text-empty-state: #2f2f30;
$border-color-text: #dadada;
$shadowSwitch: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);


//icons
$icon-logo: '/icons/icon-logo.svg';
$icon-booking: '/icons/icon-booking.svg';
$icon-booking-active: '/icons/icon-active-booking.svg';
$icon-car: '/icons/icon-car.svg';
$icon-car-active: '/icons/icon-active-car.svg';
$icon-reminder: '/icons/icon-reminder.svg';
$icon-reminder-active: '/icons/icon-active-reminder.svg';
$icon-employees: '/icons/icon-employees.svg';
$icon-employees-active: '/icons/icon-active-employees.svg';
$icon-profile: '/icons/icon-profile.svg';
$icon-profile-active: '/icons/icon-active-profile.svg';
$icon-btn-back: '/icons/icon-btn-back.svg';
$icon-checked: '/icons/icon-checked.svg';
$icon-price: '/icons/icon-price.svg';
$icon-sign-in: '/icons/icon-sign-in.svg';
$icon-plus: '/icons/icon-plus.svg';
$icon-search: '/icons/icon-search.svg';
$icon-product-default: '/icons/icon-product-default.svg';
$icon-ellipsis: '/icons/icon-ellipsis.svg';
$icon-close-search: '/icons/icon-close-search.svg';
$icon-filter: '/icons/icon-filter.svg';
$icon-calendar: '/icons/icon-calendar.svg';
$icon-add-booking: '/icons/icon-add-booking.svg';
$icon-booking-calendar: '/icons/icon-booking-calendar.svg';
$icon-clock: '/icons/icon-clock.svg';
$icon-employee: '/icons/icon-employee.svg';
$icon-car-booking: '/icons/icon-car-booking.svg';
$icon-phone: '/icons/icon-phone.svg';
$icon-empty-state: '/icons/icon-empty-state.svg';
$icon-calendar-gray: '/icons/icon-calendar-gray.svg';
$icon-eye: '/icons/icon-eye.svg';
$icon-eye-visible: '/icons/icon-eye-visible.svg';
$icon-error: '/icons/icon-error.svg';
$icon-x: '/icons/icon-x.svg';
$icon-success: '/icons/icon-success.svg';
$icon-pin: '/icons/icon-pin.svg';
$icon-basket: '/icons/icon-basket.svg';
$icon-add-employee: '/icons/icon-add-employee.svg';
$icon-empty-employee: '/icons/icon-empty-employee.svg';
$icon-add-reminder: '/icons/icon-add-reminder.svg';
$icon-empty-reminders: '/icons/icon-empty-reminders.svg';
$icon-empty-vehicle: '/icons/icon-empty-vehicle.svg';
$icon-add-vehicle: '/icons/icon-add-vehicle.svg';


$tablet-max-width: 1366px;

//fonts

$font-main-regular: 14px MontserratRegular;

:export {
  iconActiveBackground: $icon-active-background;
  mainColor: $main-color;
  shadowSwitch: $shadowSwitch
}



