@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.info-day {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.day-off-text{
  color: $side-bar-color;
  font: 14px MontserratMedium;
  margin-top: 12px;
  margin-right: 30px;
}

