@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.filter-container {
  height: 100%;
  width: 270px;
  border-radius: 15px 0 0 15px;
  position: absolute;
  right: 0;
  background-color: $side-bar-color;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.filter-wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 37px 14px 0 20px;
}

.filter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.title-filter {
  font: 20px 'Montserrat';
  letter-spacing: -0.05px;
  color: $white;
}

.filter-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: flex-start;
  width: 100%;
}

.field-label {
  opacity: 0.6;
  font: 12px MontserratRegular;
  color: $main-color;
  margin-bottom: 5px;
}

.filter-select {
  background-color: $icon-active-background;
  width: 100%;
  font: $font-main-regular;
  color: $white;
  height: 32px;
}


.filter-select-wrap {
  width: 100%;

  .css-1wa3eu0-placeholder {
    color: $placeholder-filter;
  }

  .css-1uccc91-singleValue {
    color: $white;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 172px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }
  .filter-select__menu {
    &.css-26l3qy-menu {
      border-radius: 5px;
      box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.21);
      background-color: $icon-active-background;
      border: none;
      color: $white;
    }
  }
  .css-9gakcf-option {
    background-color: rgba(80, 79, 83, 0.4);
    color: $main-color;
    width: 100%;
    font: 14px MontserratRegular;
  }
  .css-1n7v3ny-option {
    background-color: $icon-active-background;
  }

  .filter-select__control {
    height: 32px;
    background-color: $icon-active-background;
    border-radius: 5px;
    border: none;
    min-height: 32px;
    max-height: 32px;
    outline: none;

    &.filter-select__control:hover {
      border: none;
      box-shadow: none;
      outline: none;

    }

    &.filter-select___container:focus {
      border: none;
      outline: none;
    }

  }

  &:active,
  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}


.input-filter {
  width: 100%;
  height: 32px;
  border-radius: 5px;
  padding: 7px 0 7px 10px;
  border: none;
  outline: none;
  background-color: $icon-active-background;
  font: 14px MontserratRegular;
  -moz-appearance:textfield;
  -webkit-appearance: none;
  color: $white;

  &::placeholder {
    color: $placeholder-filter;
  }
}

.line-filter {
  width: 230px;
  height: 1px;
  border: solid 1px $icon-active-background;
  margin-bottom: 20px;
}

.filter-reset-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.reset-filter-text {
  font: 12px Montserrat;
  opacity: 0.6;
  color: $main-color;
  cursor: pointer;
  border-bottom: 1px solid $main-color;
}