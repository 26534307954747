@import 'assets/styles/general.scss';
@import 'assets/styles/variables.scss';

.container-calendar {
  width: 390px;
  height: 385px;
  position: absolute;
  margin-top: 30px;
  z-index: 1002;
}

.custom-calendar {
  width: 390px;
  border: none !important;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22) !important;
  background-color: #333333 !important;
}

.button-cancel-calendar {
  background-color: $white;
  color: #222222;
  width: 100px;
}

.react-calendar {
  width: 391px !important;
  max-width: 100%;
}

.react-calendar__month-view__days__day--neighboringMonth {
  font-family: MontserratRegular;
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: -0.34px;
  text-align: center;
  color: #757576;
}

.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  color: white;
  font-family: MontserratRegular;
  font-size: 14px;
  background: none;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
  background-color: #f10038 !important;
  color: $white !important;
}

.react-calendar__tile--active {
  color: #f10038 !important;
  background-color: #333333 !important;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  font: 14px MontserratRegular !important;
  line-height: 1.57;
  letter-spacing: -0.34px;
  text-transform: none !important;
  color: $white;
}

.bbr[title], abbr[title] {
  text-decoration: none !important;
  border-bottom: 0;
  cursor: pointer;
}

.react-calendar__navigation button {
  min-width: 44px;
  background: none;
  color: white !important;
}

.react-calendar__month-view__days__day--weekend {
  color: white !important;
}

.button-calendar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #333333;
  height: 80px;
  border-radius: 0 0 5px 5px;
}

.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
  box-shadow: none;
}

.react-calendar__navigation {
  height: 44px;
  margin: 0;
}

.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
  background-color: #333333 !important;
}

.react-calendar__tile--now {
  background: #333333 !important;
  color: red;
}