@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.verification-page-container, .profile-info-page {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &.profile-info-page {
    overflow-y: scroll;
    padding-bottom: 30px;
  }
}

.verification-page-wrap, {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  padding-top: 40px;
  border-radius: 15px;
  width: 1015px;
  height: 562px;
  background-color: $white;
}

.verify-text, .verify-sub-text {
  font: 34px Montserrat;
  letter-spacing: -0.09px;
  color: $text-color-input;

  &.verify-sub-text {
    font: 14px MontserratRegular;
    margin: 30px 0 30px 0;
  }

}

.input-password {
  width: 500px;
}

.button-submit-verify {
  width: 100px;
  background-color: $color-text-button;
  font-size: 14px;
  color: $white;
}


