@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.sign-in-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 45px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px;

  &.error-wrap {
    margin-bottom: 5px;
  }
}

.sign-in-text {
  color: black;
  font: 34px Montserrat;
  margin: 35px 0 35px 0;
  text-align: center;
  letter-spacing: -0.09px;
  width: 575px;
}

.button-sign-in {
  background-color: $color-text-button;
  color: $white;
  width: 100px;
  margin-right: 20px;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.55;
  }
}

.input-sign-in {
  width: 325px;
}

.icon-sign-in-background {
  background-color: $main-color;
  border-radius: 50%;
  width: 138px;
  height: 138px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-recovery {
  width: auto;
  background-color: $icon-active-background;
  margin-top: 35px;
  color: $white;

  &.disabled {
    cursor: not-allowed;
    opacity: 0.8;
  }
}

.error-text {
  font: 10px MontserratRegular;
  color: $color-text-button;
  margin-left: 2px;
}

.restore-email {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.notification {
  width: 370px;
  height: 90px;
  border-radius: 15px;
  box-shadow: $shadow-notification;
  background-color: $white;
}

.notification-error, .notification-success {
  width: 370px;
  height: 90px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.message {
  font: $font-main-regular;
  line-height: 1.43;
  letter-spacing: normal;
  color: $text-color-input;
}

.notification-error:before {
  background-image: url($icon-error);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  content: '';
}

.notification-error:after, .notification-success:after {
  background-image: url($icon-x);
  width: 17px;
  height: 17px;
  margin-left: 10px;
  margin-bottom: 25px;
  background-repeat: no-repeat;
  background-position: center;
  content: '';
}

.notification-success:before {
  background-image: url($icon-success);
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-position: center;
  content: '';
}




