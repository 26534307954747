@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.booking-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
}


.booking-page-container {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
  width: 1015px;
  position: relative;
  overflow-y: scroll;
  flex: 1;
}

.filter-panel {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 30px;
}

.button-container {
  display: flex;
  flex-direction: row;
}

.btn {
  background-color: $icon-active-background;
  display: flex;
  align-items: center;
  color: $white;
  margin-left: 20px;

  &_filter {
    width: 118px;
  }

  &_calendar {
    width: 160px;
  }

  &_booking {
    width: 180px;
    background-color: $color-text-button;
  }
}

.empty-state-booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  height: 558px;
  border-radius: 15px;
  background-color: $white;
  margin-top: 10px;
}

.empty-state-text {
  font: 14px MontserratRegular;
  letter-spacing: 0.5px;
  text-align: center;
  color: $text-color-input;
}

.modal-add-booking {
  width: 600px;
  height: 680px;
  padding: 0 40px 0 40px;
}

.container-booking-list {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  padding: 10px 0 20px 0;
}

.sort-text {
  font: $font-main-regular;
  color: $main-color;
  margin-right: 10px;
}

.sorting-select {
  background-color: $bg-search;
  width: 161px;
  font: $font-main-regular;
  color: $main-color;
  height: 32px;
}


.sorting-select-wrap {
  width: 172px;

  .css-1uccc91-singleValue {
    color: $main-color;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 172px;
    overflow: hidden;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    box-sizing: border-box;
  }

  .sorting-select__control {
    height: 32px;
    background-color: $bg-search;
    border-radius: 5px;
    border: solid 1px $border-color;
    min-height: 32px;
    max-height: 32px;
    outline: none;

    &.sorting-select__control:hover {
      border: solid 1px $border-color;
      box-shadow: none;
      outline: none;

    }

    &.sorting-select___container:focus {
      border: solid 1px $border-color;
      outline: none;
    }

    &:active,
    &:focus {
      outline: none;
    }

  }

  .css-9gakcf-option {
    background-color: rgba(199, 199, 199, 0.4);
    color: $white;
  }
}

.sorting-select__menu {
  &.css-26l3qy-menu {
    border-radius: 5px;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.22);
    border: solid 1px $border-color;
    background-color: $bg-search;
    color: $white;
  }
}

.css-tlfecz-indicatorContainer {
  padding: 0 5px 0 0 !important;
}

.css-1gtu0rj-indicatorContainer {
  padding: 0 0 0 5px !important;
}

