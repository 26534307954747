@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.item-product {
  width: 500px;
  height: 157px;
  border-radius: 15px;
  background-color: $white;
  margin-bottom: 10px;
}

.item-product-wrap {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.img-container {
  min-width: 125px;
  min-height: 125px;
  max-width: 125px;
  max-height: 125px;
  border-radius: 15px;
  background-size: cover;
  background-position: center;
  margin: 16px 0 16px 16px;
}

.product-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 16px 16px 16px 20px;
}

.product-info-wrap, .price-wrap {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.price-wrap {
    justify-content: flex-start;
  }
}

.name-product, .price-text, .price-currency, .edit-item, .delete-item, .text-remove-item {
  width: 229px;
  font: 20px Montserrat;
  letter-spacing: -0.05px;
  color: $text-color-input;

  &.text-remove-item {
    width: auto;
    margin-bottom: 20px;
  }

  &.price-text {
    font: 14px MontserratRegular;
    width: auto;
    margin-right: 5px;
  }

  &.price-currency {
    font-size: 14px;
    width: auto;
  }

  &.edit-item, &.delete-item {
    font-size: 14px;
    width: auto;
    margin: 15px 20px 0 20px;
    cursor: pointer;

    &.delete-item {
      color: $color-text-button;
      margin-bottom: 15px;
    }
  }


}

.menu {
  max-width: 205px;
  min-width: 100px;
  height: auto;
  border-radius: 5px;
  box-shadow: $menu-shadow;
  background-color: $white;
  position: absolute;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.menu-wrap {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &--employee {
    margin-left: 30px;
  }
}

.button-menu {
  width: 40px;
  box-shadow: $button-shadow-modal;
  background-color: $white;
}

.modal-remove-item {
  width: 590px;
  height: 192px;
}

.content-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.css-g1d714-ValueContainer {
  font: 14px MontserratRegular;
  color: $text-color-input;
}

.confirm-request-modal {
  width: 592px;
  max-height: 485px;
  padding-bottom: 35px;
}
