@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.container-button {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.booking {
  width: 135px;
  position: relative;
  background-color: $white;
  border-radius: 5px 0 0 5px;
  color: $select-color;
  font: $font-main-regular;

  &--active {
    background-color: $color-text-button;
    color: $white;
  }

  &-requests {
    border-radius: 0 5px 5px 0;
  }

}

.count-requests {
  width: 22px;
  height: 12px;
  border-radius: 6.5px;
  font: 9px 'Montserrat';
  color: $text-color-input;
  margin: 5px 8px 0 0;
  background-color: $white;
  position: absolute;
  top: 0;
  right: 0;

  &--not-active {
    background-color: $color-text-button;
    color: $white;
  }
}