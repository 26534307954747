@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.item-vehicle {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 20px 30px 5px 30px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  background-color: $white;
}
