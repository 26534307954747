@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';


.item-employee {
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 15px;
  padding: 20px 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: $white;
}

.employee-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name-employee {
  font: 14px MontserratRegular;
  letter-spacing: 1.17px;
  color: $text-color-input;
  margin-left: 10px;

  &--phone {
    width: 162px;
  }
}
