@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.photo-gallery {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  width: 100%;
  height: 100%;

  &__wrap {
    min-width: 1024px;
    max-width: 1050px;
    margin: 22px 0 20px 0;
    align-items: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    overflow-y: scroll;
  }
}

.photo-gallery-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

@media all and (max-width: $tablet-max-width) {
  .photo-gallery {
    &__wrap {
      max-width: 670px;
      min-width: 670px;
    }
  }
}
