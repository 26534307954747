@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.side-bar-container, .side-bar-open {
  height: 100%;
  width: 70px;
  background-color: $side-bar-color;
  display: flex;
  z-index: 1001;
  flex-direction: column;

  &.side-bar-open {
    width: 270px;
    position: absolute;
  }

  .side-bar-icon {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 144px;
  }
}

.icon-wrap, .icon-wrap-active {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 5px 30px 5px;
  cursor: pointer;
  height: 60px;

  &.icon-wrap-active {
    border-radius: 5px;
    box-shadow: $shadow-btn-side-bar;
    background-color: $icon-active-background;
  }
}

.title-icon, .title-icon-active {
  color: $side-bar-text;
  margin-left: 10px;
  font: 14px 'Montserrat';
  letter-spacing: -0.04px;

  &.title-icon-active {
    color: $white;
  }

  &--none {
    display: none;
  }
}

a.icon {
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  padding: 0 15px 0 10px;
  object-fit: contain;
  width: 65px;
  height: 40px;

  &-booking {
    background-image: url($icon-booking);
  }

  &-booking-active {
    background-image: url($icon-booking-active);
  }

  &-car {
    background-image: url($icon-car);
  }

  &-car-active {
    background-image: url($icon-car-active);
  }

  &-reminder {
    background-image: url($icon-reminder);
  }

  &-reminder-active {
    background-image: url($icon-reminder-active);
  }

  &-employees {
    background-image: url($icon-employees);
  }

  &-employees-active {
    background-image: url($icon-employees-active);
  }

  &-profile {
    background-image: url($icon-profile);
  }

  &-profile-active {
    background-image: url($icon-profile-active);
  }

}

.container-logo {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 28px 14px 0 7px;
}

.logo-text {
  color: $white;
  margin-left: 25px;
  font: 14px 'Montserrat';
  letter-spacing: -0.04px;

  &--none {
    display: none;
  }
}

