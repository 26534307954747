@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';


.edit-profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  &__wrap {
    width: 1024px;
    height: 554px;
    background-color: $white;
    margin: 30px 0 20px 0;
    align-items: flex-start;
    flex-direction: row;
    border-radius: 15px;
    padding: 35px 30px;

    &__password {
      width: 600px;
      height: 300px;
      flex-direction: column;
    }
  }
}

.PhoneInputInput {
  width: 520px;
  background-color: $main-color;
  padding: 7px 10px 7px 10px;
  border-radius: 5px;
  border: none;
  height: 32px;
  color: $text-color-input;
  font: 14px MontserratRegular;
}

.PhoneInputCountry {
  display: none !important;
}

.input-edit-name {
  width: 520px;
  margin-bottom: 20px;
}

.phone-number-wrap {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.navigation-wrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.edit-profile-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
}

.profile {
  display: flex;
  flex-direction: column;
}

.container-maps {
  width: 465px;
  height: 484px;
  margin-right: 32px;
}
