@import 'assets/styles/variables.scss';
@import 'assets/styles/general.scss';

.container-employee {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.input-employee {
  width: 100%;
  box-shadow: none;
}

.title-modal-employee {
  font: 20px Montserrat;
  margin-bottom: 20px;
  letter-spacing: -0.05px;
  color: $text-color-input;
}

.button-wrap-employee {
  display: flex;
  flex-direction: row;
  align-items: center;
}

