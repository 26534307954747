@import 'assets/styles/variables.scss';


.modal-container {
  position: fixed;
  z-index: 1100;
  border-radius: 15px;
  top: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  outline: none;
}

.ReactModal__Overlay {
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.3) !important;
}
