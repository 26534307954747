@import 'assets/styles/general.scss';
@import 'assets/styles/variables.scss';

.add-item-booking {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
  width: 100%;
  height: 100%;
}

.container-data-time {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 20px 0 20px 0;
}

.datepicker {
  display: flex;
  align-items: center;

  &-icon {
    right: 10px;
    background-image: url($icon-calendar-gray);
    display: block;
    width: 18px;
    height: 18px;
    transform: translateX(-30px);
  }
}

.title-input {
  opacity: 0.6;
  font: 12px MontserratRegular;
  color: $title-input-color;
  margin-bottom: 5px;
  line-height: normal;
  letter-spacing: normal;
}

.data-wrap {
  display: flex;
  flex-direction: column;
  width: 160px;
}

.input-date-picker {
  width: 140px;
}

.ant-picker-input > input {
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  padding: 4px 11px;
  color: $side-bar-color;
  font-size: 14px;
  line-height: 1.5715;
  border-radius: 2px;
  transition: all 0.3s;
  flex: auto;
  height: auto;
  background: transparent;
  border: 0;

  &::placeholder {
    color: $placeholder-color;
  }
}

.booking-text-wrap {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
}

.info-text {
  height: 85px;
  width: 100%;
  border-radius: 5px;
  color: $side-bar-color;
  margin-bottom: 20px;
  background-color: $main-color;
  padding: 7px 10px 7px 10px;
  font: 14px MontserratRegular;
  resize: none;
  border: none;

  &::placeholder {
    color: $placeholder-color;
  }
}

.radio-button-container {
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  width: 100%;
}

.radio {
  margin-right: 55px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-radio-button {
  margin-left: 10px;
  color: $radio-button-title;
  font: 14px MontserratRegular;
}

input[type='radio'] {
  position: relative;
  height: 20px;
  width: 20px;
  appearance: none;
  outline: none;
}

input[type='radio']::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  background-color: $white;
  border: solid 1px #cccccc;
}

input[type='radio']:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: $icon-active-background;
  transform: translate(-50%, -50%);
  visibility: visible;
}

.vehicle-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin: 20px 0 20px 0;
}

.input-vin-number {
  width: 250px;
}

.input-owner-name {
  width: 520px;
}

